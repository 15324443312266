export const productQuantity = (product) => {
    if(product.quantity === 0) {
        return ['preorder', 'supplier'].includes(product.availability) ? product.preorderQuantity : 0;
    }

    return product.quantity > product.maxQuantity ? product.maxQuantity : product.quantity;
}

export const cartProductQuantity = (product) => {
    if(product.availableQuantity === 0) {
        return ['preorder', 'supplier'].includes(product.availability) ? product.availablePreorderQuantity : 0;
    }

    return product.availableQuantity > product.maxQuantity ? product.maxQuantity : product.availableQuantity;
}

export const canAddToCart = (product, type, context) => {
    return productQuantity(product) > context.isInCart(
        product.id,
        type === "single" ? "card" : "product"
    )
}

export const getAvailability = (product) => {
    if(product.quantity > 0) {
        return 'available';
    }

    if(['preorder', 'supplier'].includes(product.availability) && product.preorderQuantity > 0) {
        return product.availability;
    }

    return 'unavailable';
}

export const getCartAvailability = (product) => {
    if(product.availableQuantity > 0) {
        return 'available';
    }

    if(['preorder', 'supplier'].includes(product.availability) && product.availablePreorderQuantity > 0) {
        return product.availability;
    }

    return 'unavailable';
}
