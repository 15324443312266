import React, { useState } from "react";
import Icon from "react-icons-kit";
import { shoppingBasket } from "react-icons-kit/fa";
import theme from "../../../config/theme";

import { spinner } from "react-icons-kit/fa";
import { u1F630 } from 'react-icons-kit/noto_emoji_regular/u1F630';


const AddToCartButton = (props) => {

    let noStockTimeout = null;

    const [noStock, setNoStock] = useState(false);
    const cartIcon= props.loading ? spinner : shoppingBasket;

    const onClick = (e) => {
        if (props.disabled) {
            setNoStock(true);
            clearTimeout(noStockTimeout);
            noStockTimeout = setTimeout(() => {
                setNoStock(false);
            }, 700)
        } else {
            if(props.loading) return;

            props.onClick(e);
        }
    }

    return (
        <button onClick={onClick} className={`${props.big ? 'big' : ''} ${props.disabled && noStock ? 'disabled' : ''}`}>
            <style jsx>{`
            button {
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
                border: 0;
                color: ${theme.colors.base.white};
                ${props.availability === 'preorder' ?
                    `background: linear-gradient(to right, ${theme.colors.gradients.purpleB} 10%,${theme.colors.gradients.purpleA});` :
                props.availability === 'supplier' ?
                    `background: linear-gradient(to right, ${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});` :
                    `background: linear-gradient(to right, ${theme.colors.gradients.redA} 10%,${theme.colors.gradients.redB});`}
                width: 100%;
                font-weight: bold;
                text-transform: uppercase;
                box-sizing: border-box;
                height: 42px;
                font-size: 1.2em;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                border-radius: 3px;
                overflow: hidden;
                transition: background 0.1s ease-out;
                opacity: 0.9;
            }

            button:not(.disabled):hover {
                box-shadow:0 0 10px #01A59B;
            }


            button:not(.disabled):active {
                outline: none;
                filter: saturate(1.4);
            }

            button:focus {
                outline: none;
            }


            .cart-icon {
                background: linear-gradient(to right,${theme.colors.base.black} 10%,${theme.colors.base.base});
                height: 42px;
                width: 32px;
                max-width: 42px;
                padding: 0px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                min-width: 0%;
                max-width: 100%;
            }


            .text {
                transition-delay: 0.02s;

                flex-grow: 1;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                min-width: 0%;
                max-width: 100%;
                transition: min-width 0.1s ease-in;
                white-space: nowrap;

                font-size:1rem;
            }

            .column {
                display: flex;
                flex-direction: column;
            }

            .xl-text {
                font-size: 1.1rem;
            }

            .text.inline {
                lex-direction: row;
            }

            .subtext {
                font-size: 0.8rem;
                font-style: italic;
                font-weight: 300;
                text-transform: uppercase;
                width: 100%;
            }

            .cart-icon.active {
                //background: ${theme.colors.gradients.blueA};
                font-size: 1.6rem;
            }

            @media only screen and (max-width: ${theme.responsive.m}px) {
                button {
                    min-width: 100px;
                    font-size: 0.9rem;
                    padding: 0;
                }

                .cart-icon {
                    width: 32px;
                }
            }

            @media only screen and (max-width: ${theme.responsive.m}px) {
                ${props.big ? 'font-size: 1rem;' : 'font-size: 0.8rem;'}
            }

            .wide {
                min-width: 100%;
                border-radius: 0;
            }

            .narrow {
                max-width: 0%;
            }

            .very-tiny {
                font-size: 0.6rem;
            }

            .loading-icon {
                -webkit-animation:spin 2s linear infinite;
                -moz-animation:spin 2s linear infinite;
                animation:spin 2s linear infinite;
            }

            @media only screen and (max-width: ${theme.responsive.s}px) {
                .xl-text {
                    font-size: 1rem;
                }
            }

            @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
            @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
            @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

        `}</style>
                <div className={`cart-icon ${props.disabled && noStock ? 'wide' : ''} ${props.inCart && !noStock ? 'active' : ''}`}>
                    {props.disabled && noStock ?
                        <div className="text"><Icon size={32} icon={u1F630}></Icon> &nbsp; Stoc insuficient</div> :
                        <span className={` ${props.loading ? 'loading-icon' : ''}`}>
                            {props.inCart && !props.loading ?
                               <div>
                                    <div>{props.inCart}</div>
                                    <div className="very-tiny">IN COS</div>
                                </div>
                            : <Icon size={30} icon={cartIcon} /> }
                        </span>}
                </div>
                <div className={`text xl-text column ${props.disabled && noStock ? 'narrow' : ''}`} >
                    <span>
                        {props.disabled && noStock ? '' :
                            props.text || 'Adauga in Cos'}
                    </span>
                    {props.availability === 'supplier' ?
                        <span className="subtext">
                            In Stoc furnizor
                        </span> :
                    props.availability === 'preorder' ?
                        <span className="subtext">
                            Precomanda
                        </span> :
                    props.availability === 'available' ?
                        <span className='subtext'>
                            In stoc
                        </span> : ''}
                </div>
        </button>
    )
}

export default AddToCartButton;
