import React from "react";
import theme from "../../../config/theme";

const ProductTag = (props) => {
    return (
        <div className="product-tag">
            <style jsx>{`
                .product-tag {
                    font-size: 1rem;
                    text-transform: uppercase;
                    position: absolute;
                    ${props.left ? "left: 0px;" : "right: 0px;"}
                    ${props.bottom ? "bottom: 20px; " : "top: 0px;"}
                    background-color: ${props.variant === "price" ? theme.colors.base.red : theme.colors.base.accent};

                    ${props.variant === "cart" ? `
                        background: linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});
                    ` : ''}

                    ${props.variant==="preorder" ? `
                        background: linear-gradient(to right,${theme.colors.gradients.purpleA} 10%,${theme.colors.gradients.purpleB});
                    `: ''}

                    ${props.variant==="supplier" ? `
                        background: linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});
                    `: ''}

                    ${props.variant === "price" ? `
                        background: linear-gradient(to right,${theme.colors.gradients.blueB} 10%,${theme.colors.gradients.greenA});
                        font-size: 1.30rem;
                    ` : ''}

                    color: ${props.variant === "price" ? theme.colors.base.white : theme.colors.base.white};
                    padding: 5px;
                    ${props.variant === "price" ? `

                        width: 48px; height: 48px;
                        padding: 5px;
                    ` : ''}

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: ${props.variant === "price" ? "900" : "bold"};

                    ${props.round ? "border-radius: 100%;" : 'border-radius: 5px;'}
                    text-shadow: 0px 0px 1px #000000;

                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .product-tag {
                        font-size: 1.0em;
                        padding: 5px;
                        ${props.bottom ? "bottom: 10px; " : "top: 10px;"}

                        ${props.variant === "price" ? `

                        width: 34px; height: 34px;
                    ` : ''}

                    }
                }
            `}</style>
            {props.children}
        </div>
    )
}

export default ProductTag;
