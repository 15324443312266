import React from "react";
import { getAvailability } from "../../../utils/product";
import AddToCartButton from './AddToCartButton';
import UnavailableButton from './UnavailableButton';

const ProductAddToCart = (props) => {
    return (
        <div className={`add-to-cart ${props.canAdd ? '' : 'disabled'}`}>
            <style jsx>{`
                .add-to-cart {
                    margin-top: 0px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

            `}</style>
            {props.forceAdd || props.canAdd ?
                <AddToCartButton
                    availability={getAvailability(props.product)}
                    inCart={props.inCart}
                    loading={props.loading}
                    onClick={props.onClick}
                    big={props.big}
                    disabled={props.canAdd ? false : true} /> :
                <UnavailableButton disabled/>}
        </div>
    )
}

export default ProductAddToCart;
